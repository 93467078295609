.customCardHeader{
    z-index: 0 !important;
}

.react-datepicker-popper {
    z-index: 1000 !important;
}

.background {
    background-image: url('https://images.unsplash.com/photo-1543788303-c15e49305bc6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80');
    background-size: cover;
    background-position: top center;
    height: 100vh;
    overflow: hidden
}
.makeStyles-drawerPaper-6 {
    box-shadow: none !important;
}
.ck-editor__editable {
    min-height: 150px !important;
}
.makeStyles-blue-23{
    box-shadow: none !important;
}
.makeStyles-blue-23:hover{
    box-shadow: none !important;
}
.loginURL:hover {
  text-decoration: underline;
}
