/* CustomDatePicker.css */
.custom-datepicker-container {
    width: 100%;
  }
  
  .custom-datepicker {
    width: 100%;
    height: 50px; /* Increase height to 50px */
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Optional: Adjust the input and dropdown styles */
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
  }