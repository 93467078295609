/*!

 =========================================================
 * Material Dashboard React - v1.8.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  /* .badge-primary-background-color: #9c27b0; */
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li>a:hover,
.fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}

.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

* {
  letter-spacing: normal !important;
}

.gridItem {
  padding: 0px !important;
  background-color: #00bbff !important;
}

.timeInput {
  text-align: center !important;
  min-width: 120px !important;
}

.timeNewInput {
  text-align: center !important;
  min-width: 20px !important;

  -webkit-user-select: all !important;
  user-select: all !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  height: 35px;
  position: absolute;
  width: 100%;
  left: -10%;
  z-index: 1;
}

.endTimeUpdate {
  font-size: 15px;
  display: block;
  border: 1px solid #0000003b;
  padding: 9px;
  border-radius: 3px;
  position: relative;
  top: 8px;
}

.validityStart {
  color: red;
  position: relative;
  top: 8px;
}

.validityEnd {
  color: red;
  position: relative;
  top: 8px;
}

input:invalid+.validityEnd:after {
  content: "can not select past time";
}

input:valid+.validityEnd:after {
  content: "";
}

input:invalid+.validityStart:after {
  content: "can not select future time";
}

input:valid+.validity:after {
  content: "";
}

:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #0000003b !important;
}

/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0000003b !important;
  color: #0000003b !important;
} */

.timeEntryDetails {
  padding: 0px !important;
}

.hover-underLine:hover {
  text-decoration: underline !important;
}

.addButtonFocus:focus {
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}

.two-line-dot {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.report-primary {
  background-color: #4caf50 !important;
  color: #ffffff !important;
}

.report-secondary {
  background-color: #ffd54f !important;
  color: #000000 !important;
}

.datepicker {
  background-color: #ffffff;
}

.datepicker:hover {
  background-color: #eeeeee;
}

/* coustom Input  */
.text {
  display: table;
  margin: 20px auto;
}

table th {
  /* min-width: 150px; */
}

.t-dropdown-block {
  width: 100%;
  position: relative;
}

.t-dropdown-list-close {
  display: none;
  background-color: #fff;
  border: 1px solid #0000003b;
  z-index: 1000;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  position: absolute;
  margin-top: 10px;
  width: 100%;
}

.t-dropdown-list-remark-close {
  display: none;
  background-color: #fff;
  border: 1px solid #0000003b;
  z-index: 1000;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  position: absolute;
  margin-top: 10px;
  width: 100%;
}

.t-dropdown-list-open {
  display: block;
  background-color: #fff;
  border: 1px solid #0000003b;
  z-index: 10;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 230px;
  overflow: auto;
  position: absolute;
  margin-top: 50px;
  width: 100%;
}

.t-dropdown-list-remark-open {
  display: block;
  background-color: #fff;
  border: 1px solid #0000003b;
  z-index: 10;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 180px;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  margin-top: 5px;
  width: 100%;
  right: -30%;
  top: 30px;
}

.t-dropdown-item {
  padding: 7px 20px;
  margin: 0;
  cursor: pointer;
}

.t-dropdown-item:hover {
  background-color: #f961173d;
}

.t-dropdown-select {
  border: 1px solid #0000003b;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-sizing: content-box;
}

.t-dropdown-input {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
  border: 0;
  height: 40px;
  width: 100%;
  padding: 0 3px 0 10px;
  box-sizing: border-box;
  font-size: 16px;
}

/* width */
.t-dropdown-list-open::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.t-dropdown-list-open::-webkit-scrollbar-thumb {
  background: #0096ff;
}

.MuiDrawer-paper {
  margin-top: 71px !important;
}

table {
  border-collapse: collapse;
}

tr {
  border: none;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip>.bg-transparent {
  width: 150px !important;
}

.tooltiptext {
  width: 150px !important;
}

.tooltip-ram {
  color: rgb(114, 114, 114);
  width: 200px;
  top: 100%;
  left: 58%;
  margin-left: -60px;
  padding: 10px;
  text-align: start;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(255, 255, 255);
  color: #000000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptextOne {
  visibility: hidden;
  width: 120px;
  background-color: rgb(255, 255, 255);
  color: #000000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextOne {
  visibility: visible;
}

.tooltip .tooltiptext {
  color: rgb(114, 114, 114);
  width: 200px;
  top: 125%;
  left: 58%;
  margin-left: -60px;
  padding: 10px;
  text-align: start;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.tooltip .tooltiptextOne {
  color: rgb(114, 114, 114);
  width: 250px;
  top: 100%;
  left: 58%;
  margin-left: -60px;
  padding: 10px;
  text-align: start;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.hover-late:hover .second-date {
  display: contents;
  background: #ffca2acf !important;
  color: rgb(255, 255, 255);
  margin-right: 6px;
  cursor: pointer;
  height: 36px;
  width: 100px !important;
  font-size: 14px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: none;
}

.bg-transparent {
  background-color: transparent !important;
}

.hover-late:hover .first-date {
  display: none;
}

.hover-late .second-date {
  display: none;
}

.hover-late:hover {
  margin-right: 6px;
  transition: 0.25s ease-in-out;
  width: 100px !important;
  border-radius: 50px !important;
  background: #ffca2acf;
}

.hover-late {
  margin-right: 6px;
  transition: 0.15s ease-in-out;
  width: 36px !important;
  border-radius: 50% !important;
  background: #ffca2acf;
}

.table-row:hover {
  background-color: #deecfc9d;
}

/* Handle */
.t-dropdown-list-open::-webkit-scrollbar-thumb {
  background: #f96117db;
}

.padding_zero {
  padding: 0px 0px !important;
}

.history_spacing {
  margin: 100px !important;
  margin-top: 100px !important;
}

.tableLastBorder:last-child {
  border-bottom: 1px solid #0000001a !important;
}

.time-entry-border-bottom:hover {
  background-color: #f961171c;
}

.tableLastBorder:hover {
  /* background-color:#f961171c ; */
  background-color: #a7a3a11c;
  border-radius: 10px;
  cursor: pointer;
}

.note-image {
  visibility: hidden !important;
  background-color: transparent;
  border-radius: 50px;
  margin: 0px 10px;
}

.note-image:hover {
  background-color: rgb(212, 212, 212);
  border-radius: 10px;
  margin: 0px 10px;
}

.stop-chip {
  margin-left: 12px;
}

.tableLastBorder:hover .note-image {
  visibility: visible !important;
}

.time-details-array::-webkit-scrollbar {
  width: 5px !important;
  height: 0px !important;
}

.time-details-array::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.time-details-array::-webkit-scrollbar-thumb {
  background: #f4590d !important;
}

.time-details-array::-webkit-scrollbar-thumb:hover {
  background: #f4590d !important;
}

/* overHeere */
.overHeere:hover {
  text-decoration: underline;
  cursor: pointer;
}

.HW_badge_cont {
  visibility: visible;
  pointer-events: none;
  display: block;
  cursor: pointer;
  width: 100px !important;
  height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  left: -75px !important;
}

.HW_badge {
  width: 14px !important;
  height: 14px !important;
  top: 3px !important;
  font-size: 9px !important;
  line-height: 14px !important;
  left: 80px !important;
  position: relative !important;
}

.HW_frame_cont {
  left: 50px !important;
}

/* rmsc    ui */
.rmsc .select-item {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--rmsc-p);
  outline-offset: -1px;
  outline-color: var(--rmsc-primary);
  color: black !important;
}

.rmsc .options::-webkit-scrollbar {
  width: 5px !important;
  height: 0px !important;
}

.rmsc .options::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.rmsc .options::-webkit-scrollbar-thumb {
  background: #f4590d !important;
}

.rmsc .options::-webkit-scrollbar-thumb:hover {
  background: #f4590d !important;
}

.rmsc .select-item:hover {
  background-color: #f961173d;
}

.rmsc .search input:focus {
  background: white;
}

.rmsc .select-item.selected {
  background: #f961173d;
}

.calendarFilterButton {
  width: 6rem;
  border-radius: 10px;
  padding: 5px;
  margin: 5px 10px;
  cursor: pointer;
  border: 1px solid #f96117db;
  font-size: 13px;
}

.cursor-default {
  cursor: default !important;
}

/* avatar-hover */
.avatar-hover:hover {
  background-color: #e9e9e9;
  padding: 6px 8px;
  border-radius: 5px;
}

/* custome switch */
.switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  position: relative;
}

.toggle-thumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 40px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 30px;
}

.toggle-thumb:before {
  content: "";
  height: 26px;
  width: 26px;
  position: absolute;
  left: 4px;
  bottom: 3px;
  border-radius: 50%;
  background-color: #e2e8f0;
  transition: 0.4s all ease;
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox:checked+.toggle-thumb:before {
  transform: translateX(26px);
}

@keyframes changewidth {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

.time-format {
  animation-duration: 0.4s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.scheduler_header {
  /* margin: 7rem; */
  display: flex;
  color: black;
  /* justify-content: center; */
}

.date_picker {
  /* margin-left: 80px; */
  border: none;
}

.user_list {
  /* margin-left: 80px; */
  overflow: auto;
  max-height: 40rem;
  /* min-height: 40rem; */
  /* overflow-y: auto; */
  width: 100%;
  border: 1px solid #e7e7e7;
  max-width: 100%;
}

/* .tooltip-details {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Noto_Sansregular;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Noto_Sansregular;
  font-weight: 600;
} */

.tooltip-details {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Noto_Sansregular,
    system-ui, sans-serif;
  font-weight: 600;
  /* Apply a font weight of 600 */
}

.user_list::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

.user_list::-webkit-scrollbar-thumb {
  background: #f4590d;
  /* background: #888; Color of the scrollbar handle */
}

@media (max-height: 800px) {
  .user_list {
    max-height: 61vh !important;
    /* min-height: 10vh !important; */
  }
}

@media (min-height: 768px) and (max-height: 800px) {
  .user_list {
    max-height: 1% !important;
  }
}

/* @media (min-height: 768px) {
  .user_list {
    max-height: 1% !important;
  }
} */

.css-1nmdiq5-menu {
  z-index: 9999 !important;
}

.user_list~table,
th {
  /* border: 1px solid #e7e7e7; */
  border-collapse: collapse;
}


.makeStyles-content-3 {
  /* background: white; */
}

.table-container {
  max-width: 100%;
  height: 2rem !important;
}

.table-container table {
  border-collapse: collapse;
  width: auto;
  table-layout: fixed;
}

/* Fix the first column */
.table-container th:first-child,
.table-container td:first-child {
  /* position: sticky;
  left: 0; */
  /* z-index: 99; */
  /* background-color: #fff; */
}

th {
  /* background-color: #f2f2f2 !important; */
}

th,
td {
  padding: 5px;
  /* border: 1px solid #e7e7e7; */
}

td:first-child {
  /* position: sticky;
  left: 0;
  background-color: #f2f2f2; */
  /* z-index: 1; */
}

/* tr:nth-child(even) {
  background-color: #f9f9f9;
} */

/* Sticky Header */
thead th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 10;
}

/* th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 10;
} */

/* 
.table-container th:nth-child(1),
.table-container td:nth-child(1),
.table-container th:nth-child(2),
.table-container td:nth-child(2),
.table-container th:nth-child(3)
{
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
} */

/* Add right border to the fixed column */
.table-container th:first-child::after .table-container td:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #e7e7e7;
  /* border-left: 1px solid #e7e7e7; */
  z-index: 99;
}

/* .table-container th:second-child::after .table-container td:second-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #e7e7e7;
  z-index: 99;
} */

.table-container td:first-child::after {
  /* content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%; */
  /* border-right: 1px solid #e7e7e7 !important; */
  /* border-left: 1px solid #e7e7e7; */
  /* z-index: 99; */
}

.table-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* width: 30px; */
  /* Adjust as needed */
  height: 100%;
  /* background-color: #fff; */
  /* Adjust background color to match */
  z-index: 2;
}

/* .table-container th:first-child {
  z-index: 2;
} */

/* Ensure the first three columns have a higher z-index */
.table-container th:nth-child(1)

/* .table-container th:nth-child(2) */
/* .table-container th:nth-child(3)  */
  {
  z-index: 99;
}

.table-container th:nth-child(2)

/* .table-container th:nth-child(2) */
/* .table-container th:nth-child(3)  */
  {
  z-index: 99;
}

.dropdown-container {
  z-index: 99;
}

.project-cell {
  border-radius: 5px;
  padding: 5px 0px;
  color: black;
  text-align: center;
  font-size: 14px;
  margin: -1rem 0px;
  /* border: 1px solid #e7e7e7; */
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
  border-top: none;
  /* height: 3rem !important; */
}

.project-row {
  /* border-right: 1px solid #e7e7e7 !important;
  border-bottom: none !important; */
  /* border: none !important; */
}

.project-details:hover .project-tooltiptext,
.project-tooltiptext {
  /* width: 5rem; */
  /* display: contents; */
  visibility: visible;
  position: fixed;
  overflow: hidden;
}

.project-details {
  text-decoration: none;
  position: relative;
}

.project-tooltiptext:hover .project-tooltiptext {
  /* visibility: visible; */
}

.project-details .project-tooltiptext {
  background-color: rgb(255, 255, 255);
  color: #000000;
  text-align: center;
  padding: 20px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  /* left: 300px; */
  z-index: 10;
}

.project-tooltiptext {
  /* display: none; */
  visibility: hidden;
  color: rgb(114, 114, 114);
  width: 250px;
  top: 100%;
  /* left: 58%; */
  /* margin-left: -60px; */
  padding: 2rem;
  /* text-align: start; */
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  z-index: 10;
  /* position: absolute; */
  /* border: 1px solid #e7e7e7; */
  background-color: red;
}

.plus-icon-row {
  border-bottom: 1px solid #e7e7e7 !important;
}

@media (min-width: 481px) and (max-width: 767px) {
  .new-allocation {
    padding: 0rem;
  }
}